<template>
    <div class="book_collect radius">
        <p class="book_collect_title">TA们收藏了此书</p>
        <div class="book_collect_list" flex column >
            <div v-for="(item,index) in options" :key="index" class="book_collect_list_item" flex>
               <!-- @click="user_info(item)" -->
                <img :src="item.avatar" v-if="item.avatar">
                <img src="../../../assets/images/default_header.png" v-else>
                <p>{{item.nickname||"- -"}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
      options:{
        type:Array
      }
    },
    data() {
        return {
            img:require('@/assets/images/hear_01.jpg')
        }
    },
    methods: {
        user_info(row){
          if(row.is_vip===1){
          }
            // 去到用户详细信息页面
        }
    },
}
</script>
<style lang="scss" scoped>
    .book_collect {
        margin-top: 10px;
        height: 700px;
        font-size: 12px;
        color: #333333;
        .book_collect_title {
            font-size: 18px;
            padding: 5px 0 10px 0;
            border-bottom: 1px solid #e4e4e4;
        }
        .book_collect_list {
            height: calc(100% - 40px);
            justify-content: flex-start;
            .book_collect_list_item {
                line-height: 50px;
                padding: 5px 0;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    // cursor: pointer;
                }
                p {
                    width: calc(100% - 50px);
                    padding-left: 40px;
                    // cursor: pointer;
                }
                // p:hover {
                //    color: rgb(49, 141, 254);
                // }
            }
            .book_collect_list_item:hover {
                // color: rgb(49, 141, 254);
            }
        }
    }
</style>
