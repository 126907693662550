<template>
  <div class="book_new_chaptor radius" flex column between v-if="book_info.new_section">
    <p class="new_chapter_title">{{book_info.name}}</p>
    <p>
      <span>最新章节</span>
      <span @click="read_book(book_info.page)">{{
        book_info.new_section
      }}</span>
      <span>{{ book_info.new_seciton_time }}</span>
    </p>
    <p
      class="content"
      words-hidden
      line-clamp
      v-html="book_info.new_section_content"
    ></p>
  </div>
</template>
<script>
import { books_detail } from "@/https/read_books.js";
export default {
  // 模拟数据
  data() {
    return {
      author_info: {},
      book_info: {
        // id: 1,
        // new_chapter: "第八章 这你可就不懂了吧",
        // time: "2020-01-01 11.22.12",
        // intro:
        //   "摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内容摘要内",
      },
    };
  },
  props: {
    options: {
      type: Object,
    },
  },
  created() {
    this.avoid_info();
  },
  methods: {
    // 加载最新章节信息
    avoid_info() {
      let params = {
        id: Number(sessionStorage.getItem("book_id")),
      };
      books_detail(params).then((res) => {
        this.book_info = res.data;
      });
    },
    get_data() {
      let params = {
        book_id: sessionStorage.getItem("book_id"),
        author_id: sessionStorage.getItem("author_id"),
      };
      // 根据id从后台获取数据或者从父组件获取数据传递过来，考虑到子组件路由添砖过来，在子组件请求数据，仅需id号
    },
    // 阅读
    read_book(page) {
      this.$router.push({
        path: "/read_book",
        query: {
          id: Number(sessionStorage.getItem("book_id")),
          page: page,
        },
      });
    },
    // lister() {
    //   window.addEventListener("setItem", () => {
    //     let id = sessionStorage.getItem("watchStorage");
    //     this.avoid_info(id);
    //   });
    // },
  },
  mounted() {
    this.get_data();
  },
  watch: {
    "$route.query.id"() {
      this.avoid_info();
    },
  },
};
</script>
<style lang="scss" scoped>
.book_new_chaptor {
  height: 230px;
  padding: 10px 20px;
  color: #666666;
  .new_chapter_title {
    padding: 5px 0 10px 0;
    border-bottom: 1px solid rgba(228, 228, 228, 1);
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
  & p:nth-of-type(2) {
    font-size: 18px;
    & span:nth-of-type(2) {
      // color: #339;
      color: #409eff;
      margin: 0 40px 0 20px;
      cursor: pointer;
    }
    & span:nth-of-type(2):hover {
      color: #409eff;
    }
    & span:last-of-type {
      color: #999999;
      font-size: 14px;
    }
  }
  & p:last-of-type {
    font-size: 14px;
    overflow: hidden;
    // padding: 20px 0;
    letter-spacing: 2px;
    max-height: 88px;
    line-height: 22px;
    min-height: 88px;
  }
}
// .content {

//   //   overflow:hidden;
//   //   display:-webkit-box;
//   //   -webkit-box-orient:vertical;
//   //   -webkit-line-clamp:4;
// }
</style>
