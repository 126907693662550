<template>
<div :class="'score_star' + (showNumber ? '' : ' big')" flex>
    <div class="item" v-for="(_, index) in new Array(10)" :key="index"
         @click="submitScore"
         @mousemove="giveScore($event, index)">
        <img v-if="(edit ? editScore : score)>=index+1" src="./../../../assets/images/details/starf.png" alt="">
        <img v-else-if="(edit ? editScore : score)<=index" src="./../../../assets/images/details/stare.png" alt="">
        <img v-else src="./../../../assets/images/details/starh.png" alt="">
    </div>
    <div class="numbers" v-if="showNumber">
        {{ editScore ? Number(editScore).toFixed(2) : Number(score || 0).toFixed(2) }}
    </div>
</div>
</template>

<script>
    import {submit_score} from "@/https/read_books.js";
    export default {
        name: "scoreStar",
        props: {
            score: Number, //得分
            showNumber: Boolean, //是否显示数字文本
            edit: Boolean, //是否可以评分
            book_id: String || Number
        },
        data(){
            return {
                editScore: 0, //编辑的分数
            }
        },
        methods: {
            giveScore(e, index){
                if(!this.edit)return ;
                let containerInfo = e.target.getBoundingClientRect(),
                    starWidth = containerInfo.width,
                    starLeft = containerInfo.left,
                    mouseLeft = e.pageX;
                if(mouseLeft - starLeft < (starWidth / 2)){
                    this.editScore = index + 0.5;
                }else{
                    this.editScore = index + 1;
                }
            },
            submitScore(){
                if(!this.edit) return ;
                if(this.had_login()){
                    submit_score({
                        book_id: sessionStorage.getItem("book_id"),
                        score: this.editScore
                    }).then(res=>{
                        if(res.code === 0){
                            this.$store.state.messageVue.$emit('getBookDetail');
                            this.$message.success("评分成功")
                        }
                    })
                }else{
                    this.$message.warning("登录后才能评分哦~");
                    this.$store.commit("set_login_type", true);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .score_star{
        display: inline-flex;
        align-items: flex-end;
        .item{
            img{
                width: 17px;
                margin: 0 2px;
                cursor: pointer;
            }
        }
        &.big{
            padding: 0 12px;
            .item img{
                width: 21px;
            }
        }
        .numbers{
            font-size: 24px;
            color: #F56C6C;
            line-height: 31px;
            font-weight: bold;
        }
    }
</style>
