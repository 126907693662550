<template>
  <div class="fans-rank">
    <div class="fans-rank-title">
      <img src="./../../../assets/images/details/fans.png" alt="" />
      <div class="text">铁杆粉丝榜</div>
    </div>
    <div class="no-fans" v-if="!list.length">这本书还没有粉丝哦～</div>
    <div v-if="list.length">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="no">{{ index + 1 }}</div>
          <div class="head-img" v-if="index < 3">
            <img :src="item.user.avatar || '1'" alt="" />
          </div>
          <div class="role" v-if="index > 2">{{ item.fen_title }}</div>
          <div class="rank-user">
            <div class="user-name-con">
              <div class="name">{{ item.user.nickname }}</div>
              <div class="tag tag-vip" v-if="index < 3 && item.user.is_vip">
                VIP
              </div>
              <div class="tag tag-level" v-if="index < 3 && item.user.level">
                {{ item.user.level }}
              </div>
            </div>
            <div class="role" v-if="index < 3">{{ item.fen_title }}</div>
          </div>
          <div class="coins">
            {{ item.fens }}
            <img src="./../../../assets/images/details/coins.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getFansRank } from "@/https/read_books.js";

export default {
  name: "fans_rank",
  data() {
    return {
      list: [],
      defaultAvator: require("./../../../assets/images/default_header.png"),
      deaultImage:
        'this.src="' +
        require("./../../../assets/images/default_header.png") +
        '"',
    };
  },
  mounted() {
    this.getData();
    this.$store.state.messageVue.$on("costAction", this.getData);
  },
  computed: {
    getDetail() {
      return this.$store.state.book_info;
    },
  },
  watch: {
    "getDetail.id": function () {
      this.list = [];
      this.getData();
    },
  },
  methods: {
    getData() {
      getFansRank({
        book_id: this.getDetail.id,
        page: 1,
        limit: 10,
      }).then((res) => {
        if (res.code === 0) {
          this.list = (res.data && res.data.list && res.data.list.data) || [];
        }
      });
    },
  },
};
</script>

<style lang="scss">
.fans-rank {
  margin-top: 15px;
  padding: 15px;
  background-color: #fff;

  .fans-rank-title {
    display: flex;

    .text {
      padding-left: 17px;
      flex-grow: 2;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }

    img {
      width: 23px;
      height: 21px;
    }
  }

  .no-fans {
    line-height: 40px;
    color: #999;
    text-align: center;
  }

  ul {
    margin-top: 10px;
    list-style: none;
    border-bottom: 1px solid #eeeeee;

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 8px;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        padding-left: 0;

        .coins {
          transform: translateY(-30%);
        }
      }

      &:nth-child(1) {
        .no {
          color: #ff9812;
        }
      }

      &:nth-child(2) {
        .no {
          color: #d9001b;
        }
      }

      &:nth-child(3) {
        .no {
          color: #409eff;
        }
      }

      .no {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }

      .head-img {
        min-width: 40px;
        width: 40px;
        height: 40px;
        margin: 0 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .user-name-con {
        display: flex;
        align-items: center;
      }

      .rank-user {
        flex-grow: 2;

        .role {
          margin-top: 11px;
        }
      }

      & > .role {
        margin-left: 9px;
        margin-right: 12px;
      }

      .role {
        display: inline-block;
        padding: 0 7px;
        line-height: 19px;
        font-size: 14px;
        background: #ffe589;
        border-radius: 5px;
        color: #a95709;
      }

      .name {
        max-width: 7em;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4398cc;
        font-size: 16px;
        line-height: 18px;
        white-space: nowrap;
      }

      .tag {
        margin-left: 6px;
        padding: 0 6px;
        color: #fff;
        line-height: 18px;
        border-radius: 5px;

        &.tag-vip {
          background: #d9001b;
        }

        &.tag-level {
          background: #409eff;
        }
      }

      .coins {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        white-space: nowrap;
        font-size: 14px;
        color: #fbc61e;

        img {
          width: 14px;
          vertical-align: -3px;
        }
      }
    }
  }
}
</style>
