import { render, staticRenderFns } from "./new_chapter.vue?vue&type=template&id=ba8af934&scoped=true&"
import script from "./new_chapter.vue?vue&type=script&lang=js&"
export * from "./new_chapter.vue?vue&type=script&lang=js&"
import style0 from "./new_chapter.vue?vue&type=style&index=0&id=ba8af934&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba8af934",
  null
  
)

export default component.exports