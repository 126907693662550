<template>
  <div>
    <div class="book_home" flex>
      <div class="book_home_left">
        <newChaptor></newChaptor>
        <bookComment></bookComment>
      </div>
      <div class="book_home_right">
        <bookScore :data="others_books || {}"></bookScore>
        <throwTicket></throwTicket>
        <rewardGift></rewardGift>
        <div v-if="others_books.author_id > 0 && others_books.other_books">
          <bookOther :options="others_books"></bookOther>
        </div>
        <bookCollect
          :options="collect_man"
          v-if="collect_man.length > 0"
        ></bookCollect>
        <fansRank v-if="getDetail.id" />
      </div>
    </div>
    <div class="book_home_bottom">
      <bookCommended
        :options="commended"
        :author_id="others_books.author_id"
        v-if="commended.length > 0"
      ></bookCommended>
    </div>
  </div>
</template>
<script>
import newChaptor from "@/components/show_book/left/new_chapter";
import bookComment from "@/components/show_book/left/book_comment";
import bookOther from "@/components/show_book/right/book_other";
import bookCommended from "@/components/show_book/right/book_commended";
import bookCollect from "@/components/show_book/right/book_collect";
import bookScore from "@/components/show_book/right/book_score";
import throwTicket from "@/components/show_book/right/throw_ticket";
import rewardGift from "@/components/show_book/right/reward_gift";
import fansRank from "@/components/show_book/right/fans_rank";
import { detail_sec } from "@/https/read_books.js";

export default {
  components: {
    newChaptor,
    bookComment,
    bookOther,
    bookCommended,
    bookCollect,
    bookScore,
    throwTicket,
    rewardGift,
    fansRank,
  },
  data() {
    return {
      collect_man: [],
      commended: [],
      others_books: {},
    };
  },
  created() {
    this.avoid_msg();
  },
  mounted() {},
  computed: {
    getDetail() {
      return this.$store.state.book_info;
    },
  },
  methods: {
    // 加载数据：收藏书籍列表、推荐列表、其他书籍列表
    avoid_msg() {
      let params = {
        id: Number(sessionStorage.getItem("book_id")),
      };
      detail_sec(params).then((res) => {
        if (res.code === 0) {
          this.collect_man = res.data.like_user.splice(0, 9);
          this.commended = res.data.recommend.splice(0, 5);
          this.others_books = res.data;
        }
      });
    },
    // lister() {
    //   window.addEventListener("setItem", () => {
    //     let id = sessionStorage.getItem("watchStorage");
    //     this.avoid_msg(id);
    //   });
    // },
  },
  watch: {
    "$route.query.id"() {
      this.avoid_msg();
    },
  },
};
</script>
<style lang="scss" scoped>
.book_home {
  width: 100%;

  .book_home_left,
  .book_home_right {
    border-radius: 5px;
  }

  .book_home_left {
    width: 74%;
  }

  .book_home_right {
    width: 25%;
    margin-left: 12px;
  }
}
</style>
