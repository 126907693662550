<template>
  <div class="book_other radius" v-if="options.other_books && options.other_books.length>0">
    <p class="book_other_title" flex between>
      <span>作者其他作品</span>
      <span v-if="options.author_id!==0" black @click="author_intro(options.author_id)">更多 &gt</span>
    </p>
    <div
      flex
      class="book_other_head"
      @click="options.author_id ? author_intro(options.author_id) : ''"
    >
      <img
        :src="options.image"
        width="50px"
        height="50px"
        v-if="options.image"
      />
      <img
        src="../../../assets/images/default_header.png"
        width="50px"
        height="50px"
        v-else
      />
      <p :class="options.author_id ? 'intro_author' : 'un_author'">
        {{ options.author_name }}
      </p>
    </div>
    <div class="book_other_bottom" >
      <div flex v-if="options.other_books && options.other_books.length>0">
        <img
          :src="options.other_books[0].cover_image"
          v-if="options.other_books[0].cover_image"
          @click="book_info(options.other_books[0].id)"
        />
        <img src="../../../assets/images/default.png" v-else />
        <div flex column between class="book_other_right" >
          <p @click="book_info(options.other_books[0].id)">
            {{ options.other_books[0].name||'- -' }}
          </p>
          <p>
            分类：{{
              options.other_books[0].labels &&
              options.other_books[0].labels.length
                ? options.other_books[0].labels[0].name
                : "暂无"
            }}
          </p>
        </div>
      </div>
      <div class="book_other_list" >
        <div v-for="index in 4" :key="index" >
          <p @click="book_info(options.other_books[index].id)" v-if="index >= 1 && index < 6">
            <span>{{ index + 1 }}</span>
            <span>{{ options.other_books[index].name }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 可以在父组件请求数据在此处使用即可
      author_info: {
        name: "作家昵称",
        id: 1,
        header: require("@/assets/images/hear_01.jpg"),
        book_arr: [
          // {
          //   id: 1,
          //   book_name:
          //     "作品名称作品名称作品名称作品名称作品名称作品名称作品名称作品名称",
          //   classify: "分类标签",
          //   book_img: require("@/assets/images/book_bc.jpg"),
          // },
          // {
          //   id: 2,
          //   book_name: "作品名称",
          //   classify: "分类标签",
          // },
          // {
          //   id: 3,
          //   book_name: "作品名称",
          //   classify: "分类标签",
          // },
          // {
          //   id: 4,
          //   book_name: "作品名称",
          //   classify: "分类标签",
          // },
          // {
          //   id: 5,
          //   book_name: "作品名称",
          //   classify: "分类标签",
          // },
          // {
          //   id: 6,
          //   book_name: "作品名称",
          //   classify: "分类标签",
          // },
        ],
      },
    };
  },
  props: {
    //接收父组件传递过来的值,在父组件进行Http请求
    options: {
      type: Object,
    },
  },

  mounted(){
  },
  methods: {
    // 书籍详情
    book_info(id) {
      // 回到界面顶部
      // document.documentElement.scrollTop = -10000;
      // 在当前界面打开
      this,$router.push({
        path:"/show_book",
        query:{
          id:id
        }
      })
      // 打开新界面
      // const {href} = this.$router.resolve({
      //   path: "/show_book",
      //   query: {
      //     id: id,
      //   },
      // });
      // window.open(href, "_blank");
    },
    // 作者详情
    author_intro(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.book_other {
  height: 430px;
  color: #333333;
  .book_other_title {
    padding: 0 10px 10px 0;
    border-bottom: 1px solid #e4e4e4;
    & span:nth-of-type(1) {
      font-size: 18px;
    }
    & span:nth-of-type(2) {
      cursor: pointer;
    }
    & span:nth-of-type(2):hover {
      color: rgb(49, 141, 254);
    }
  }
  .book_other_head {
    padding: 10px 5px;
    border-bottom: 1px solid rgba(228, 228, 228, 0.5);
    img {
      border-radius: 50%;
      cursor: pointer;
    }
    .intro_author {
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
      color: #333;
      padding: 15px 15px;
    }
    .intro_author:hover {
      color: rgba(79, 156, 251, 1);
    }
    .un_author {
      font-size: 12px;
      font-weight: normal;
      color: #333;
      padding: 15px 15px;
    }
    // p {
    //     // margin-left: 15px;
    //     padding: 15px 15px;
    //     cursor: pointer;
    // }
    // p:hover {
    //     color: rgb(49, 141, 254);
    // }
  }
  .book_other_bottom {
    font-size: 12px;
    & div:nth-of-type(1) {
      padding: 10px 0;
      img {
        width: 85px;
        height: 120px;
        cursor: pointer;
      }
      .book_other_right {
        // width: calc(100% - 10px -99px);
        width: 60%;
        margin-left: 10px;
        & p:nth-of-type(1) {
          overflow: hidden;
          cursor: pointer;
        }
        & p:nth-of-type(1):hover {
          color: rgb(49, 141, 254);
        }
      }
    }
    .book_other_list {
      p {
        cursor: pointer;
        line-height: 33px;
        border-bottom: 1px solid;
        border-image: linear-gradient(
            to right,
            rgba(228, 228, 228, 0.2) 0%,
            rgba(209, 205, 205, 1) 50%,
            rgba(228, 228, 228, 0.2) 100%
          )
          2 2 2 2;
        border-image: -webkit-linear-gradient(
            to right,
            rgba(228, 228, 228, 0.2) 0%,
            rgba(209, 205, 205, 1) 50%,
            rgba(228, 228, 228, 0.2) 100%
          )
          2 2 2 2;
        & span:nth-of-type(2) {
          padding-left: 10px;
        }
      }
      p:hover {
        color: rgb(49, 141, 254);
        border-bottom: 1px solid;
        border-image: linear-gradient(
            to right,
            rgba(49, 141, 254, 0.2) 0%,
            rgba(49, 141, 254, 1) 50%,
            rgba(105, 171, 252, 0.2) 100%
          )
          2 2 2 2;
        border-image: -webkit-linear-gradient(
            to right,
            rgba(49, 141, 254, 0.2) 0%,
            rgba(49, 141, 254, 1) 50%,
            rgba(105, 171, 252, 0.2) 100%
          )
          2 2 2 2;
      }
    }
  }
}
</style>
