<template>
    <div class="book_score" :key="getDetail.my_score">
        <div class="score_title_header">
            <img src="./../../../assets/images/details/staricon.png" alt="">
            <div class="text">小说评分</div>
            <div class="numbers">已有{{ getScoreListData && getScoreListData.total_user_num || 0 }}人参与评分</div>
        </div>
        <scoreStar :score="getScore" :show-number="true" />
        <scoreBar :data="getScoreListData"></scoreBar>
        <div class="score-chart"></div>
        <div class="edit-score">
            <div class="text">您的星评: <span>(在星星上滑动评分)</span></div>
        </div>
        <scoreStar :edit="getDetail.is_score !== 1" :score="Number(getDetail.my_score)" :show-number="true" />
    </div>
</template>

<script>
    import scoreStar from './scoreStar';
    import scoreBar from '@/components/echarts/score-bar';
    export default {
        name: "book_score",
        components: {
            scoreStar,
            scoreBar
        },
        props: {
        },
        data(){
            return {
            }
        },
        computed: {
            getDetail(){
                return this.$store.state.book_info;
            },
            getScore(){
                return Number(this.$store.state.book_info.score);
            },
            getScoreListData(){
                return this.$store.state.book_info.score_user_num
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../utils/tool";

    .book_score {
        background: #FFF;
        border-radius: 5px;
        padding: 20px 15px;
        .score_title_header {
            @include flex-align-center;
            margin-bottom: 17px;
            text-align: center;
            img{
                width: 30px;
            }
            .text{
                margin: 0 9px;
                margin-left: 17px;
                color: #333;
                font-weight: bold;
            }
            .numbers{
                font-size: 14px;
                color: #999;
            }
        }
        .edit-score{
            margin: 11px 0;
            .text{
                text-align: center;
                span{
                    color: #999999;
                }
            }
        }
    }
</style>
