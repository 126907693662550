<template>
    <div ref="chart" class="score-bar"></div>
</template>

<script>
    import echarts from 'echarts'
    export default {
        name: "score-bar",
        mounted(){
            this.initEcharts()
        },
        props: {
            data: Object || String
        },
        watch: {
            data: {
                handler() {
                    this.$nextTick(function(){
                        this.initEcharts()
                    })
                },
                deep: true
            }
        },
        methods: {
            initEcharts(){
                if(!this.chart) this.chart = echarts.init(this.$refs.chart);
                const { ladder_user_num={}, total_user_num=0 } = this.data || {};
                let data = new Array(10).fill(0).map((_, index)=>{
                    if(total_user_num === 0) return 0;
                    return (Number(ladder_user_num[index+1]) * 100 / total_user_num).toFixed(0);
                });
                let options = {
                    color: ['#FF8E00'],
                    grid: {
                        top: '14%',
                        left: -20,
                        right: '2%',
                        bottom: '12%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: [1,2,3,4,5,6,7,8,9,10],
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                color: '#666'
                            }
                        }
                    ],
                    yAxis: {
                        show: false,
                        type: 'value',
                        max: 100,
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: '60%',
                            label: {
                                show: true,
                                color: '#666',
                                fontSize: 10,
                                position: 'top',
                                formatter: '{c}%'
                            },
                            data: data
                        }
                    ]
                };

                setTimeout(() => {
                    this.chart.setOption(options)
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .score-bar{
        width: 100%;
        height: 120px;
        margin: 15px 0;
        border-bottom: 1px solid #EEEEEE;
    }
</style>
