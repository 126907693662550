<template>
  <div class="book_comment radius">
    <div class="tabs">
      <div :class="{ tab: true, active: type === 1 }" @click="setType(1)">
        简评({{ short_evaluate_num }})
      </div>
      <div :class="{ tab: true, active: type === 2 }" @click="setType(2)">
        长评({{ long_evaluate_num }})
      </div>
    </div>
    <div class="book_comment_bottom">
      <commentInput @up_comment="up_comment" :options="options"></commentInput>
    </div>
    <div class="book_comment_list">
      <userComment
        :options="item || []"
        :is_author="is_author"
        v-for="(item, index) in comment_list"
        :key="index"
        @refreshlist="get_comment_list"
        :index="index"
      ></userComment>
    </div>
    <div class="book_comment_page" v-if="comment_list.length > 0" flex center>
      <cPage :page="page" :callBack="loadData"></cPage>
    </div>
  </div>
</template>
<script>
import userComment from "./user_comment";
import commentInput from "../right/commet_input";
import cPage from "../../page/c-page";
import { books_review, add_review } from "@/https/read_books.js";

export default {
  components: {
    userComment,
    commentInput,
    cPage,
  },
  data() {
    return {
      comment_list: [],
      page: {
        page: 1,
        limit: 20,
        total: 0,
      },
      options: {
        input_value: "",
      },
      is_author: false,
      type: 1, //1短评 2长评论
      long_evaluate_num: 0, //长评数目
      short_evaluate_num: 0, //长评数目
    };
  },
  mounted() {
    this.get_comment_list();
    this.$store.state.messageVue.$on("costAction", this.get_comment_list);
    this.$store.state.messageVue.$on(
      "refreshCommentList",
      this.get_comment_list
    );
  },

  methods: {
    // 去评论
    to_comm() {
      let comm = document.querySelector("#comm");
      comm.scrollIntoView(true);
      comm.focus();
    },
    // 分页
    loadData() {
      this.get_comment_list();
    },
    // 发布评论
    up_comment() {
      let params = {
        books_id: Number(sessionStorage.getItem("book_id")),
        content: this.options.input_value,
      };
      const length = this.options.input_value.length;
      add_review(params).then((res) => {
        if (res.code === 0) {
          if (length > 250 && this.type !== 2) {
            this.setType(2);
          } else if (length <= 250) {
            this.setType(1);
          }
          this.get_comment_list();
        }
      });
    },
    // 获取评论列表
    get_comment_list() {
      // 根据作品id获取评论列表
      let pramas = {
        id: Number(sessionStorage.getItem("book_id")),
        page: this.page.page,
        type: this.type,
      };
      books_review(pramas).then((res) => {
        if (res.code === 0) {
          this.comment_list = res.data.review_content;
          this.page.total = res.total;
          this.long_evaluate_num = res.data && res.data.long_evaluate_num;
          this.short_evaluate_num = res.data && res.data.short_evaluate_num;
          if (this.had_login()) {
            res.data.author_id ===
            Number(JSON.parse(sessionStorage.getItem("person_info")).id)
              ? (this.is_author = true)
              : (this.is_author = false);
          }
        }
      });
    },
    setType(type) {
      if (this.type !== type) {
        this.type = type;
        this.page.page = 1;
        this.get_comment_list();
        return;
      }
      this.type = type;
    },
  },
  watch: {
    "$route.query.id"() {
      this.get_comment_list();
    },
  },
};
</script>
<style lang="scss" scoped>
.book_comment {
  // height: 1300px;
  margin-top: 10px;
  padding: 20px;
  color: #333;

  .tabs {
    font-size: 18px;
    text-align: center;
    .tab {
      display: inline-block;
      padding: 8px 35px;
      cursor: pointer;
      &.active {
        color: #409eff;
        &:after {
          background-color: #409eff;
        }
      }
      &:after {
        content: "";
        display: inline-block;
        width: 80%;
        height: 1px;
        background-color: transparent;
        margin: 0 auto;
      }
    }
  }

  .book_comment_title {
    padding: 5px 0 10px 0;
    border-bottom: 1px solid #e4e4e4;
    font-size: 18px;

    & span:nth-of-type(2) {
      font-size: 14px;
      cursor: pointer;
      color: #666;
    }

    & span:nth-of-type(2):hover {
      color: #409eff;
    }
  }

  .book_comment_list {
    padding-top: 20px;
  }

  .book_comment_page {
    height: 60px;
    padding-bottom: 20px;

    .cPage {
      text-align: left;
    }
  }

  .book_comment_bottom {
    padding-top: 10px;
    // border-top: 1px dotted #e4e4e4;
    .book_comment_con {
      padding: 15px 0 0 0;
    }
  }
}
</style>
