<template>
  <div class="book_commended radius">
    <p class="book_commended-title" flex between>
      <span>推荐作品</span>
      <span @click="more_book">更多 &gt;</span>
    </p>
    <div class="book_commended_content" flex>
      <div
        v-for="(item, index) in options"
        :key="index"
        v-show="index < 10"
        @click="book_info(item.id)"
        class="book_commended_bottom_item"
      >
        <!-- options[item].book_img -->
        <div class="rank_one">
          <img :src="item.cover_image" alt="" />
        </div>
        <div class="book_name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
    },
    author_id: {
      type: Number,
    },
  },
  data() {
    return {
      img: require("@/assets/images/book_bc.jpg"),
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
      book_arr: [
        {
          id: 1,
          book_name:
            "作品名称作品名称作品名称作品名称作品名称作品名称作品名称作品名称",
          classify: "分类标签",
          book_img: require("@/assets/images/book_bc.jpg"),
        },
        {
          id: 2,
          book_name: "作品名称",
          classify: "分类标签",
          book_img: require("@/assets/images/book_bc.jpg"),
        },
        {
          id: 3,
          book_name: "作品名称",
          classify: "分类标签",
          book_img: require("@/assets/images/book_bc.jpg"),
        },
        {
          id: 4,
          book_name: "作品名称",
          classify: "分类标签",
          book_img: require("@/assets/images/book_bc.jpg"),
        },
        {
          id: 5,
          book_name: "作品名称",
          classify: "分类标签",
          book_img: require("@/assets/images/book_bc.jpg"),
        },
        {
          id: 6,
          book_name: "作品名称",
          classify: "分类标签",
          book_img: require("@/assets/images/book_bc.jpg"),
        },
      ],
    };
  },
  mounted() {
    // document.documentElement.scrollTop=0
  },
  methods: {
    book_info(id) {
      //去到对应书籍详情界面并将该书籍id传过去
      this.$router.push({
        path: "/show_book",
        query: {
          id: id,
        },
      });
    },
    // 作者详情
    author_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 阅读、
    read_chapter(page) {
      this.$router.push({
        path: "/read_book",
        query: {
          id: Number(sessionStorage.getItem("book_id")),
          page: page,
        },
      });
      // 去到对应书籍的对应章节去阅读，并将书籍id以及章节标识传过去
    },
    // 更多
    more_book() {
      this.$router.push("/stack_room");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../utils/tool";
.book_commended {
  overflow: hidden;
  font-size: 12px;
  color: #333333;
  margin-top: 25px;
  padding: 0 10px 0 10px;

  .book_commended-title {
    align-items: center;
    padding: 5px 10px 10px 0;
    border-bottom: 1px solid #e4e4e4;

    & span:nth-of-type(1) {
      font-size: 18px;
    }

    & span:last-of-type {
      cursor: pointer;
      padding-right: 5px;
    }

    & span:last-of-type:hover {
      color: rgb(49, 141, 254);
    }
  }

  .book_commended_content {
    padding: 10px 0 0 0;
    // img {
    //   width: 85px;
    //   height: 120px;
    //   cursor: pointer;
    // }
    .book_commended_bottom_item {
      width: 100px;
      margin: 0 15px;
      margin-bottom: 10px;
      padding-bottom: 5px;

      .rank_one {
        padding: 0;
        width: 100%;
        height: 120px;
        overflow: hidden;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 0 5px #f9f2e9 inset;

        img {
          cursor: pointer;
          width: 100%;
          height: 120px;
          transition: all 0.5s;
        }

        img:hover {
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }

      .book_name {
        @include text_ellipsis();
        max-width: 100%;
        padding: 0;
        line-height: 2em;
        text-align: center;
      }

      /*div {*/
      /*    width: calc(100% - 85px);*/
      /*    padding: 0 0 20px 10px;*/

      /*    & p:nth-of-type(1) {*/
      /*        -webkit-line-clamp: 2;*/
      /*        margin-top: 0;*/
      /*        font-size: 14px;*/
      /*        margin-bottom: 25px;*/
      /*        cursor: pointer;*/
      /*        color: #333;*/
      /*    }*/

      /*    & p:nth-of-type(1):hover {*/
      /*        color: rgb(49, 141, 254);*/
      /*    }*/

      /*    & p {*/
      /*        margin-top: 15px;*/
      /*        color: #666;*/
      /*    }*/

      /*    .intro_author {*/
      /*        font-size: 12px;*/
      /*        font-weight: normal;*/
      /*        cursor: pointer;*/
      /*        color: #666;*/
      /*    }*/

      /*    .intro_author:hover {*/
      /*        color: rgba(79, 156, 251, 1);*/
      /*    }*/

      /*    .un_author {*/
      /*        font-size: 12px;*/
      /*        font-weight: normal;*/
      /*        color: #666;*/
      /*    }*/

      /*    // & p:last-of-type{*/
      /*    //   margin-top: 20px;*/
      /*    // }*/
      /*    // p {*/
      /*    //   margin-bottom: 5px;*/
      /*    //   span {*/
      /*    //     cursor: pointer;*/
      /*    //   }*/
      /*    //   span:hover {*/
      /*    //     color: rgb(49, 141, 254);*/
      /*    //   }*/
      /*    // }*/
      /*}*/
    }
  }
}
</style>
